<template>

    <div class="start" ref="start" :class="{'start--show': app_loading}">

        <div class="logo">
            <div class="logo__img">
                <img src="@/assets/img/favicon.png" alt="Logo - Alert Szczecin 3.0">
            </div>
            <h1 class="logo__text heading-logo">Alert Szczecin</h1>

            <div class="loading-div loading"></div>
        </div>

    </div>

</template>

<script>
import {mapState} from "vuex";

export default {
    name: "StartLoading",
    computed: {
        ...mapState({
            app_loading: state => state.app.app_loading
        })
    }
}
</script>

<style scoped lang="scss">

.start {
    position: fixed;
    width: 100%;
    height: calc(100%);
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;

    background: var(--background-main);

    transition: all 0.8s ease 0s;

    opacity: 0;
    visibility: hidden;

    &--show {
        opacity: 1;
        visibility: visible;

        & > .logo {
            transform: translateY(0);
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 12px;

    height: calc(100% + 56px + 56px);
    min-height: calc(100% + 56px + 56px);

    transition: all 0.8s ease 0s;
    transform: translateY(-25px);

    &__img {

        & > img {
            width: 100%;
            max-width: 150px;
            border-radius: 12px;
        }
    }

    &__text {
        color: var(--text-black);
    }
}

.loading-div {
    height: calc(56px + 56px);
}

</style>