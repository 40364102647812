import {createRouter, createWebHistory} from 'vue-router'
import {version} from './../../package'
import toast from 'vue-toastification'

const routes = [
    {
        path: "/",
        name: "Home",
        component: function () {
            return import("@/views/Home/Show.vue")
        }
    },
    {
        path: "/activationsuccess",
        name: "AtivationSuccess",
        component: function () {
            return import("@/views/Auth/AtivationSuccess.vue")
        }
    },
    {
        path: "/reports/show",
        name: "ReportsShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Reports/Show/Show.vue")
        }
    },
    {
        path: "/contact",
        name: "ContactShow",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Contact/Show.vue")
        }
    },
    {
        path: "/about",
        name: "AboutShow",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/About/Show.vue")
        }
    },
    {
        path: "/about-app",
        name: "InfoPagesAboutShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/InfoPages/About/Show/Show.vue")
        }
    },
    {
        path: "/faq",
        name: "FAQShow",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/FAQ/Show.vue")
        }
    },
    {
        path: "/deklaracja-dostepnosci",
        name: "DeclarationOfAvailabilityWeb",
        meta: {
            // requiresNoAuth: true
        },
        component: function () {
            return import("@/views/DeclarationOfAvailability/Show.vue")
        }
    },
    {
        path: "/obowiazek-informacyjny",
        name: "InformationObligationWeb",
        meta: {
            // requiresNoAuth: true
        },
        component: function () {
            return import("@/views/InformationObligation/Show.vue")
        }
    },
    {
        path: "/declaration-of-availability-app",
        name: "DeclarationOfAvailability",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/InfoPages/DeclarationOfAvailability/Show/Show.vue")
        }
    },
    {
        path: "/regulation",
        name: "RegulationShow",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Regulation/Show.vue")
        }
    },
    {
        path: "/regulation-app",
        name: "InfoPagesRegulationShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/InfoPages/Regulation/Show/Show.vue")
        }
    },
    {
        path: "/privacy",
        name: "PrivacyShow",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Privacy/Show.vue")
        }
    },
    {
        path: "/privacy-app",
        name: "InfoPagesPrivacyShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/InfoPages/Privacy/Show/Show.vue")
        }
    },
    {
        path: "/manual",
        name: "Manual",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Manual/Show.vue")
        }
    },
    {
        path: "/news",
        name: "NewsList",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/News/List.vue")
        }
    },
    {
        path: "/news/show/:id",
        name: "NewsShow",
        meta: {
            requiresNoAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/News/Show.vue")
        }
    },
    {
        path: "/ticket/new",
        name: "WWWTicketNew",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/WWW/Ticket/New/New.vue")
        }
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Auth/Register.vue")
        }
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Auth/Login.vue")
        }
    },
    {
        path: "/logincode/:id_user",
        name: "LoginCode",
        meta: {
            requiresNoAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Auth/LoginCode.vue")
        }
    },
    {
        path: "/loading",
        name: "Loading",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Home/Loading.vue")
        }
    },
    {
        path: "/recover",
        name: "Recover",
        meta: {
            requiresNoAuth: true
        },
        component: function () {
            return import("@/views/Auth/Recover.vue")
        }
    },
    {
        path: "/profile",
        name: "ProfileShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Profile/Show/Show.vue")
        }
    },
    {
        path: "/profile/edit",
        name: "ProfileEdit",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Profile/Edit/Edit.vue")
        }
    },
    {
        path: "/contacts",
        name: "ContactsList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Contacts/List/List.vue")
        }
    },
    {
        path: "/steps",
        name: "StepsList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Steps/List/List.vue")
        }
    },
    {
        path: "/comments",
        name: "CommentsAllList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Comments/All/List/List.vue")
        }
    },
    {
        path: "/users",
        name: "UsersList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Users/List/List.vue")
        }
    },
    {
        path: "/users/new",
        name: "UsersNew",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Users/New/New.vue")
        }
    },
    {
        path: "/users/edit/:id",
        name: "UsersEdit",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Users/Edit/Edit.vue")
        }
    },
    {
        path: "/users/show/:id",
        name: "UsersShow",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Users/Show/Show.vue")
        }
    },
    {
        path: "/boards/operator",
        name: "BoardsOperatorList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Boards/Operator/List/List.vue")
        }
    },
    {
        path: "/boards",
        name: "BoardsList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Boards/List/List.vue")
        }
    },
    {
        path: "/boards/new/:id",
        name: "BoardsNew",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Boards/New/New.vue")
        }
    },
    {
        path: "/boards/edit/:id",
        name: "BoardsEdit",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Boards/Edit/Edit.vue")
        }
    },
    {
        path: "/boards/show/:id",
        name: "BoardsShow",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Boards/Show/Show.vue")
        }
    },
    {
        path: "/groups",
        name: "GroupsList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Groups/List/List.vue")
        }
    },
    {
        path: "/groups/new/:id",
        name: "GroupsNew",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Groups/New/New.vue")
        }
    },
    {
        path: "/groups/edit/:id",
        name: "GroupsEdit",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Groups/Edit/Edit.vue")
        }
    },
    {
        path: "/groups/show/:id",
        name: "GroupsShow",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Groups/Show/Show.vue")
        }
    },
    {
        path: "/tickets",
        name: "TicketsList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Tickets/List/List.vue")
        }
    },
    {
        path: "/tickets/all",
        name: "TicketsAllList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Tickets/All/List/List.vue")
        }
    },
    {
        path: "/tickets/new",
        name: "TicketsNew",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Tickets/New/New.vue")
        }
    },
    {
        path: "/tickets/edit/:id",
        name: "TicketsEdit",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Tickets/Edit/Edit.vue")
        }
    },
    {
        path: "/tickets/show/:id",
        name: "TicketsShow",
        meta: {
            requiresAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Tickets/Show/Show.vue")
        }
    },
    {
        path: "/archive",
        name: "ArchiveList",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/Archive/List/List.vue")
        }
    },
    {
        path: "/map",
        name: "MyMapShow",
        meta: {
            requiresAuth: true
        },
        component: function () {
            return import("@/views/MyMap/Show/Show.vue")
        }
    },
    {
        path: "/autologin/:token",
        name: "BlankUserAutologin",
        meta: {
            requiresNoAuth: true
        },
        props: true,
        component: function () {
            return import("@/views/Blank/User/Autologin.vue")
        }
    },
    {
        path: "/:catchAll(.*)",
        component: function () {
            return import('../views/Home/Show.vue')
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    let ls_routes = JSON.parse(localStorage.getItem("routes")) || []
    let back_click = JSON.parse(localStorage.getItem("back_click")) || false

    if (!back_click) {
        ls_routes.push(from.fullPath)
        localStorage.setItem("routes", JSON.stringify(ls_routes))
    }

    localStorage.setItem("back_click", JSON.stringify(false))

    /**
     * Określa czy element docelowy wymaga autoryzacji
     * @type {boolean}
     */
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    /**
     * Określa czy element docelowy jest dostępny tylko bez autoryzacji
     * @type {boolean}
     */
    const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

    /**
     * Wartość tokena z localStorage
     * @type {string}
     */
    const token = localStorage.getItem('token');

    /**
     * Wersja aplikacji z localstorage
     * @type {string}
     */
    const app_version = localStorage.getItem('app_version');


    if (app_version !== version){
        localStorage.clear();
        localStorage.setItem('app_version', version);

        console.log("version")

        next('/');
    }

    /**
     * Jeśli strona wymaga autoryzacji a obecnie jej nei ma przenosi do logowania
     */
    if (requiresAuth && !token) {
        console.log("if (requiresAuth && !token)")
        next('/login');
    }

    /**
     * Jeśli strona nie chce autoryzowanych użytkowników a obecnie jest, przejdź moje konto
     */
    else if (requiresNoAuth && token) {
        console.log("else if (requiresNoAuth && token)")
        next('/');
    }

    /**
     * W innym wypadku przejdź tam gdzie szedłeś
     */
    else {
        console.log("else")

        next();
    }
});

export default router
