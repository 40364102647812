<template>

    <StartLoading/>

    <div class="app-wrapper">

        <div class="app-content">
            <router-view/>
        </div>

    </div>

</template>

<script>

import {mapActions, mapState} from "vuex";
import StartLoading from "@/views/Home/Part/NoLogin/Loading.vue";

export default {
    name: "App",
    components: {StartLoading},
    data: () => ({
        isPageRefreshing: false
    }),
    methods: {
        ...mapActions({
            logout: "user/logout",
            loadTicketsCount: "ticket/loadTicketsCount",
            loadWorkLabelsCount: "ticket/loadWorkLabelsCount",
            loadCommentsCount: "ticket/loadCommentsCount"
        }),

        back() {
            let routes = JSON.parse(localStorage.getItem("routes")) || [];

            localStorage.setItem("back_click", JSON.stringify(true))

            let routes_count = routes.length;

            let route_last = routes[routes_count - 1]

            if (route_last !== undefined) {
                this.$router.push(route_last);
                routes.splice(routes_count - 1, 1)
                localStorage.setItem("routes", JSON.stringify(routes))
            }
        },

        closeTab() {

            // alert("closeTab")

            let tabs_count = parseInt( localStorage.getItem("tabs_count") || '1' );
            tabs_count--

            localStorage.setItem("tabs_count", tabs_count)

            let remember_me = localStorage.getItem("remember_me")

            if (tabs_count <= 0 && this.application_type !== "mobile_app" && !remember_me) {
                // this.logout()
                console.log("logout")
            }
        }
    },
    computed: {
        ...mapState({
            application_type: state => state.app.application_type,
            info: state => state.user.info
        })
    },
    created() {

        // console.log("created")

        let tabs_count = parseInt( localStorage.getItem("tabs_count") || '0' );
        tabs_count++

        if (tabs_count <= 0) {
            tabs_count = 1;
        }

        localStorage.setItem("tabs_count", tabs_count)

        // window.addEventListener('beforeunload', this.closeTab);

    },
    unmounted() {
    },

    mounted() {
        setInterval(() => {
            this.loadTicketsCount()
            this.loadWorkLabelsCount()
            this.loadCommentsCount()
        }, 1000 * 60 * 10)
    }
}
</script>

<style lang="scss">

.app-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-content {

}

</style>