import axios from "@/axios";
import {useToast} from "vue-toastification";
import store from "@/store/index";

export const ticket = {
    state: () => ({
        user_config: localStorage.getItem("user_config") ? JSON.parse(localStorage.getItem("user_config")) : {},

        ticket_statuses: localStorage.getItem("ticket_statuses") ? JSON.parse(localStorage.getItem("ticket_statuses")) : null,
        ticket_actions: localStorage.getItem("ticket_actions") ? JSON.parse(localStorage.getItem("ticket_actions")) : null,
        ticket_boards: localStorage.getItem("ticket_boards") ? JSON.parse(localStorage.getItem("ticket_boards")) : [],
        ticket_priorities: localStorage.getItem("ticket_priorities") ? JSON.parse(localStorage.getItem("ticket_priorities")) : null,
        ticket_work_labels: localStorage.getItem("ticket_work_labels") ? JSON.parse(localStorage.getItem("ticket_work_labels")) : [],
        ticket_categories: localStorage.getItem("ticket_categories") ? JSON.parse(localStorage.getItem("ticket_categories")) : [],
        ticket_default_categories: localStorage.getItem("ticket_default_categories") ? JSON.parse(localStorage.getItem("ticket_default_categories")) : [],
        ticket_signaller_options: localStorage.getItem("ticket_signaller_options") ? JSON.parse(localStorage.getItem("ticket_signaller_options")) : null,
        ticket_location_options: localStorage.getItem("ticket_location_options") ? JSON.parse(localStorage.getItem("ticket_location_options")) : null,
        ticket_contact_options: localStorage.getItem("ticket_contact_options") ? JSON.parse(localStorage.getItem("ticket_contact_options")) : null,
        ticket_estimate_options: localStorage.getItem("ticket_estimate_options") ? JSON.parse(localStorage.getItem("ticket_estimate_options")) : null,

        ticket_unread_count: localStorage.getItem("ticket_unread_count") ? JSON.parse(localStorage.getItem("ticket_unread_count")) : {},
        tickets_count: localStorage.getItem("tickets_count") ? JSON.parse(localStorage.getItem("tickets_count")) : null,
        work_labels_count: localStorage.getItem("work_labels_count") ? JSON.parse(localStorage.getItem("work_labels_count")) : {},
        comments_count: localStorage.getItem("comments_count") ? JSON.parse(localStorage.getItem("comments_count")) : null,
        users_count: localStorage.getItem("users_count") ? JSON.parse(localStorage.getItem("users_count")) : {},
        base_stat: localStorage.getItem("base_stat") ? JSON.parse(localStorage.getItem("base_stat")) : {},

        chart_added_done: localStorage.getItem("chart_added_done") ? JSON.parse(localStorage.getItem("chart_added_done")) : null,
        chart_circle: localStorage.getItem("chart_circle") ? JSON.parse(localStorage.getItem("chart_circle")) : null,

        ticket_filters: localStorage.getItem("ticket_filters") ? JSON.parse(localStorage.getItem("ticket_filters")) : null,

        privileges: localStorage.getItem("privileges") ? JSON.parse(localStorage.getItem("privileges")) : null,

        ticket_list_params: localStorage.getItem("ticket_list_params") ? JSON.parse(localStorage.getItem("ticket_list_params")) : {},

        granted_board_ids: localStorage.getItem("granted_board_ids") ? JSON.parse(localStorage.getItem("granted_board_ids")) : []
    }),
    actions: {

        /**
         * Ustawienia użytkownika w aplikacji
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadUserConfig({commit, state}) {

            let data = {
                show_sidebar: true
            }

            localStorage.setItem("user_config", JSON.stringify(data))
            commit("userConfig", data)

        },

        /**
         * Aktualizowanie obiektu "user_config" w localStorage
         * @param commit pozwala zmieniać zmienne w "state"
         * @param data dane do zaktualizowania
         */
        setUserConfig({commit}, data) {

            localStorage.setItem("user_config", JSON.stringify(data));

            commit("userConfig", data);
        },

        loadGrantedBoardIds({commit, state}) {
            let params = {
                privilege_by_login_user: "ticket_board_work,ticket_board_manage",
                scope: "id"
            }

            let info = store.state.user.info

            if (info && info.type === 4)
                delete params["privilege_by_login_user"]

            axios
                .get("ticketboard/get", { params: params })
                .then(response => {
                    let data = response.data.data.map(obj => obj.id)
                    localStorage.setItem("granted_board_ids", JSON.stringify(data))
                    commit("grantedBoardIds", data)
                })
                .catch(error => {
                })
        },

        setTicketListParams({commit}, data) {
            localStorage.setItem("ticket_list_params", JSON.stringify(data));
            commit("ticketListParams", data);
        },

        clearTicketListParams({commit}) {
            localStorage.removeItem("ticket_list_params");
            commit("ticketListParams", {});
        },

        /**
         * Pobieranie wszystkich możliwych statusów dla zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketStatuses({commit}) {

            const toast = useToast()

            await axios
                .get("ticketstatus/get")
                .then(response => {

                    let data = response.data.data
                    data = data.sort((a,b) => a.number - b.number)

                    localStorage.setItem("ticket_statuses", JSON.stringify(data))
                    commit("ticketStatuses", data)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu statusów zgłoszenia", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie wszystkich możliwych akcji dla zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketActions({commit}) {

            const toast = useToast()

            let params = {
                scope_extra: "status_connection,role_connection"
            }

            await axios
                .get("ticketaction/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    localStorage.setItem("ticket_actions", JSON.stringify(data))
                    commit("ticketActions", data)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu akcji zgłoszenia", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie tablic zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketBoards({commit}) {

            const toast = useToast()

            let params = {
            }

            await axios
                .get("ticketboard/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    localStorage.setItem("ticket_boards", JSON.stringify(data))
                    commit("ticketBoards", data)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu tablic zgłoszeń", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie wszystkich możliwych priorytetów dla zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         @param state dostęp do zmiennych w "state"
         */
        async loadTicketPriorities({commit, state}) {

            let base_init = JSON.parse(localStorage.getItem("base_init"))
            let data = base_init["ticket_priority_options"]

            localStorage.setItem("ticket_priorities", JSON.stringify(data))
            commit("ticketPriorities", data)

        },

        /**
         * Pobieranie wszystkich możliwych ticket_work_labels
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketWorkLabels({commit}) {

            const toast = useToast()

            let params = {
                status: "live"
            }

            await axios
                .get("worklabel/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    localStorage.setItem("ticket_work_labels", JSON.stringify(data))
                    commit("ticketWorkLabels", data)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu \"ticket_work_labels\"", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie wszystkich możliwych kategorii zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketCategories({commit}) {

            const toast = useToast()

            let params = {
                sort: 'number'

            }

            await axios
                .get("ticketcategory/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    let main_categories = data.filter(obj => obj.id_top === null)
                    let sub_categories = data.filter(obj => obj.id_top !== null)

                    sub_categories.map(el => {
                        el.name = main_categories.find(obj => obj.id === el.id_top).name + " / " + el.name
                    })

                    sub_categories = sub_categories.sort((a, b) => a.name.localeCompare(b.name))

                    localStorage.setItem("ticket_categories", JSON.stringify(sub_categories))
                    commit("ticketCategories", sub_categories)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu kategorii zgłoszeń", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie wszystkich możliwych kategorii zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         */
        async loadTicketDefaultCategories({commit}) {

            const toast = useToast()

            let params = {
                sort: "number"

            }

            let info = store.state.user.info

            if (info && info.type === 2 || !info)
                params["limited"] = "no"

            await axios
                .get("ticketcategory/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    data = data.sort((a, b) => a.name.localeCompare(b.name))

                    localStorage.setItem("ticket_default_categories", JSON.stringify(data))
                    commit("ticketDefaultCategories", data)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu kategorii zgłoszeń", {
                        timeout: 2000
                    })
                })
        },

        /**
         * Pobieranie wszystkich możliwych opcji dla sygnalisty zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketSignallerOptions({commit, state}) {

            let base_init = JSON.parse(localStorage.getItem("base_init"))
            let data = base_init["ticket_signaller_options"]

            if (data.length === 0) {
                data = [
                    {
                        name: "Brak",
                        value: "no"
                    },
                    {
                        name: "Nowy signalista",
                        value: "new"
                    },
                    {
                        name: "Sygnalista z bazy",
                        value: "user"
                    },
                    {
                        name: "Ja",
                        value: "login"
                    },
                    {
                        name: "Wpiszę ręcznie",
                        value: "own"
                    }
                ]
            }

            localStorage.setItem("ticket_signaller_options", JSON.stringify(data))
            commit("ticketSignallerOptions", data)

        },

        /**
         * Pobieranie wszystkich możliwych opcji dla lokalizacji zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketLocationOptions({commit, state}) {

            let base_init = JSON.parse(localStorage.getItem("base_init"))
            let data = base_init["ticket_location_options"]

            if (data.length === 0) {
                data = [
                    {
                        name: "Brak adresu",
                        value: "no"
                    },
                    {
                        name: "Wpiszę ręcznie adres",
                        value: "own"
                    },
                    {
                        name: "Adres taki jak sygnalisty",
                        value: "signaller"
                    }
                ]
            }

            localStorage.setItem("ticket_location_options", JSON.stringify(data))
            commit("ticketLocationOptions", data)

        },

        /**
         * Pobieranie wszystkich możliwych opcji dla kontaktu zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketContactOptions({commit, state}) {

            let base_init = JSON.parse(localStorage.getItem("base_init"))
            let data = base_init["ticket_contact_options"]

            if (data.length === 0) {
                data = [
                    {
                        name: "Brak kontaktu",
                        value: "no"
                    },
                    {
                        name: "Wpiszę ręcznie dane kontaktowe",
                        value: "own"
                    },
                    {
                        name: "Dane kontaktowe takie jak do sygnalisty",
                        value: "signaller"
                    }
                ]
            }

            localStorage.setItem("ticket_contact_options", JSON.stringify(data))
            commit("ticketContactOptions", data)

        },

        /**
         * Pobieranie wszystkich możliwych opcji dla czasu oszacowania zgłoszenia
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketEstimateOptions({commit, state}) {

            let base_init = JSON.parse(localStorage.getItem("base_init"))
            let data = base_init["ticket_estimate_options"]

            localStorage.setItem("ticket_estimate_options", JSON.stringify(data))
            commit("ticketEstimateOptions", data)

        },

        /**
         * Pobieranie licznika nieprzeczytanych zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketUnreadCount({commit, state}) {

            const toast = useToast()

            let params = {
                not_show_by_user: true
            }

            let info = store.state.user.info

            if (info && info.type === 3)
                params['user_work'] = true

            if (info && info.type === 2)
                params["id_signaller"] = info.id

            await axios
                .get("ticket/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    let today = new Date()
                    let dd = String(today.getDate()).padStart(2, "0")
                    let mm = String(today.getMonth() + 1).padStart(2, "0")
                    let yyyy = today.getFullYear()
                    today = yyyy + "-" + mm + "-" + dd

                    let ticket_unread_count = {
                        count: data.length,
                        count_new: data.filter(obj => obj.create_date.split(" ")[0] === today).length
                    }

                    localStorage.setItem("ticket_unread_count", JSON.stringify(ticket_unread_count))
                    commit("ticketUnreadCount", ticket_unread_count)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu ilości nie przeczytanych zgłoszeń", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie liczników zgłoszeń dla każdej etykiety
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketWorkLabelsCounts({commit, state}) {

            const toast = useToast()

            let params = {
                scope_extra: "labels"
            }

            let info = store.state.user.info

            if (info && info.type === 3)
                params['user_work'] = true

            if (info && info.type === 2)
                params["id_signaller"] = info.id

            await axios
                .get("ticket/get", { params: params })
                .then(async response => {

                    let data = response.data.data

                    await state.ticket_work_labels.map(work_label => work_label["count"] = 0)

                    await data.map(async ticket => {

                        await ticket.labels.map(async ticket_work_label => {

                            await state.ticket_work_labels.map(work_label => {
                                if (work_label.id === ticket_work_label.id)
                                    work_label["count"] = work_label["count"] + 1
                            })

                        })

                    })

                    localStorage.setItem("ticket_work_labels", JSON.stringify(state.ticket_work_labels))
                    commit("ticketWorkLabels", state.ticket_work_labels)

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu liczników zgłoszeń dla każdej etykiety", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie licznika zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        loadTicketsCount({commit, state}) {
            let params = {
                archive: "no",
                id_top_null: true
            }

            axios
                .get("ticket/count", { params: params })
                .then(response => {

                    let data = response.data.data.count

                    localStorage.setItem("tickets_count", JSON.stringify(data))
                    commit("ticketsCount", data)
                })
                .catch(error => {
                })
        },

        /**
         * Pobieranie liczników zgłoszeń z każdą etykietą
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        loadWorkLabelsCount({commit, state}) {
            let params = {
                archive: "no",
                id_top_null: true
            }

            state.ticket_work_labels.map(work_label => {
                params["label"] = work_label.id

                axios
                    .get("ticket/count", { params: params })
                    .then(response => {
                        let data = response.data.data.count

                        state.work_labels_count[work_label.id] = data

                        localStorage.setItem("work_labels_count", JSON.stringify(state.work_labels_count))
                        commit("workLabelsCount", state.work_labels_count)
                    })
                    .catch(error => {
                    })
            })
        },

        /**
         * Pobieranie licznika komentarzy
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        loadCommentsCount({commit, state}) {
            let params = {
            }

            axios
                .get("ticketcomment/count", { params: params })
                .then(response => {

                    let data = response.data.data.count

                    localStorage.setItem("comments_count", JSON.stringify(data))
                    commit("commentsCount", data)
                })
                .catch(error => {
                })
        },

        /**
         * Pobieranie licznika użytkowników
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadUsersCount({commit, state}) {

            const toast = useToast()

            let params = {

            }

            await axios
                .get("user/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    let today = new Date()
                    let dd = String(today.getDate()).padStart(2, "0")
                    let mm = String(today.getMonth() + 1).padStart(2, "0")
                    let yyyy = today.getFullYear()
                    today = yyyy + "-" + mm + "-" + dd


                    let users_count = {
                        count: data.length,
                        count_new: data.filter(obj => (obj.create_date ? obj.create_date.split(" ")[0] : "") === today).length
                    }

                    localStorage.setItem("users_count", JSON.stringify(users_count))
                    commit("usersCount", users_count)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu ilości użytkowników", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie danych do statystyk
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadBaseStat({commit, state}) {

            const toast = useToast()

            let params = {

            }

            await axios
                .get("base/stat", { params: params })
                .then(response => {

                    let data = response.data.data

                    localStorage.setItem("base_stat", JSON.stringify(data))
                    commit("baseStat", data)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu ilości użytkowników", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie danych dla wykresu (dodane-zakończone)
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadChartAddedDone({commit, state}) {

            const toast = useToast()

            let params = {
            }

            await axios
                .get("ticket/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    let chart_added_done = []
                    let days = ["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sb"]

                    for (let i = 6; i >= 0; i--) {
                        let date = new Date()
                        date.setDate(date.getDate() - i)
                        date = date.toISOString().split("T")[0]
                        chart_added_done.push({
                            date: date,
                            day: days[new Date(date).getDay()],
                            added: data.filter(obj => obj.create_date.split(" ")[0] === date).length,
                            done: data.filter(obj => (obj.date_work_end ? obj.date_work_end.split(" ")[0] : "") === date).length
                        })
                    }

                    localStorage.setItem("chart_added_done", JSON.stringify(chart_added_done))
                    commit("chartAddedDone", chart_added_done)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu ilości zgłoszeń", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie danych dla wykresu (circle)
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadChartCircle({commit, state}) {

            const toast = useToast()

            let params = {
            }

            await axios
                .get("ticket/get", { params: params })
                .then(response => {

                    let data = response.data.data

                    let chart_circle = {
                        added: data.filter(obj => obj.archive === "no").length,
                        done: data.filter(obj => obj.date_work_end !== null).length
                    }

                    localStorage.setItem("chart_circle", JSON.stringify(chart_circle))
                    commit("chartCircle", chart_circle)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu ilości zgłoszeń", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie filtrów dla zgłoszeń
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadTicketsFilters({commit, state}) {

            const toast = useToast()

            let params = {
            }

            await axios
                .get("basefilter/get", { params: params })
                .then(response => {

                    let data = response.data.data


                    localStorage.setItem("ticket_filters", JSON.stringify(data))
                    commit("ticketFilters", data)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu filtrów dla zgłoszeń", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie uprawnień dla zalogowanego użytkownika
         * @param commit zmiania zmiennych w "state" przez "mutations"
         * @param state dostęp do zmiennych w "state"
         */
        async loadPrivileges({commit, state}) {

            const toast = useToast()

            let params = {
                scope_extra: "privileges"
            }

            await axios
                .get("base/init", { params: params })
                .then(response => {

                    let data = response.data["privileges"]


                    localStorage.setItem("privileges", JSON.stringify(data))
                    commit("privileges", data)
                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu uprawnień dla użytkownika", {
                        timeout: 2000
                    })
                })

        }
    },
    mutations: {

        userConfig(state, data) {
            state.user_config = data
        },

        grantedBoardIds(state, data) {
            state.granted_board_ids = data
        },

        ticketListParams(state, data) {
            state.ticket_list_params = data
        },

        ticketStatuses(state, data) {
            state.ticket_statuses = data
        },

        ticketActions(state, data) {
            state.ticket_actions = data
        },

        ticketBoards(state, data) {
            state.ticket_boards = data
        },

        ticketPriorities(state, data) {
            state.ticket_priorities = data
        },

        ticketWorkLabels(state, data) {
            state.ticket_work_labels = data
        },

        ticketCategories(state, data) {
            state.ticket_categories = data
        },

        ticketDefaultCategories(state, data) {
            state.ticket_default_categories = data
        },

        ticketSignallerOptions(state, data) {
            state.ticket_signaller_options = data
        },

        ticketLocationOptions(state, data) {
            state.ticket_location_options = data
        },

        ticketContactOptions(state, data) {
            state.ticket_contact_options = data
        },

        ticketEstimateOptions(state, data) {
            state.ticket_estimate_options = data
        },

        ticketUnreadCount(state, data) {
            state.ticket_unread_count = data
        },

        ticketsCount(state, data) {
            state.tickets_count = data
        },

        workLabelsCount(state, data) {
            state.work_labels_count = data
        },

        commentsCount(state, data) {
            state.comments_count = data
        },

        usersCount(state, data) {
            state.users_count = data
        },

        chartAddedDone(state, data) {
            state.chart_added_done = data
        },

        chartCircle(state, data) {
            state.chart_circle = data
        },

        ticketFilters(state, data) {
            state.ticket_filters = data
        },

        privileges(state, data) {
            state.privileges = data
        },

        baseStat(state, data) {
            state.base_stat = data
        },
    },
    getters: {},
    namespaced: true,
}
