import { createStore } from 'vuex'

import {app} from '@/store/app';
import user from '@/store/user';
import {ticket} from '@/store/ticket';

export default createStore({
  modules: {
    app: app,
    user: user,
    ticket: ticket
  }
})
