import axios from 'axios'

let headers = {
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': true,
    'Content-Type': 'application/json',
};

/**
 * Wartość tokena z localStorage
 * @type {string}
 */
const token = localStorage.getItem('token');
if (token)
    headers['token'] = token;

/**
 * Wybór wybranej domeny
 */
const domain = localStorage.getItem('domain_selected_id');
if (domain)
    headers['Domain-id'] = domain;

headers['Domain-id'] = 9138;

const baseURL = 'https://serwerapp.alertszczecin3.pl/';

export default axios.create({
    baseURL: baseURL,
    crossDomain: true,
    mode: 'no-cors',
    headers: headers
})
